<template>
  <div id="admin-dashboard">
    <!-- Annoucements -->
    <annoucementsDashbordComponent></annoucementsDashbordComponent>
    <!-- Annoucements -->

    <v-row class="">
      <v-col cols="6" sm="6">
        <h2 class="main-color dashboard-title">{{ $t("Dashboard") }}</h2>
      </v-col>
      <!-- <v-col class="text-right" v-if="is_imployee" cols="6" sm="6">
        <v-btn class="modal-btn-save" to="/leaverequest">Requests</v-btn>
      </v-col> -->
    </v-row>
    <div class="box">
      <div class="item-card card1 item item1">
        <v-row>
          <v-col cols="4" class="text-center">
            <div class="image">
              <v-img
                src="../../assets/icons/student.svg"
                max-width="35"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="8">
            <div class="item-content">
              <p class="num main-color">
                {{ counts["total_students"] }}
              </p>
              <p class="value">{{ $t("Total Student") }}</p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="item-card card2 item item2">
        <v-row>
          <v-col cols="4" class="text-center">
            <div class="image">
              <v-img src="../../assets/icons/parent.svg" max-width="35"></v-img>
            </div>
          </v-col>
          <v-col cols="8">
            <div class="item-content">
              <p class="num">{{ counts["total_parents"] }}</p>
              <p class="value">{{ $t("Total Parent") }}</p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="item-card card3 item item3">
        <v-row>
          <v-col cols="4" class="text-center">
            <div class="image">
              <v-img
                src="../../assets/icons/teacher.svg"
                max-width="35"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="8">
            <div class="item-content">
              <p class="num">{{ counts["total_teachers"] }}</p>
              <p class="value">{{ $t("Total Teacher") }}</p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="item-card card4 item item4">
        <v-row>
          <v-col cols="4" class="text-center">
            <div class="image">
              <v-img
                src="../../assets/images/pendingPayment.svg"
                max-width="35"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="8">
            <div class="item-content">
              <v-col class="text-right" v-if="is_imployee" cols="5" sm="5">
                <v-btn class="modal-btn-save" to="/leaverequest">{{
                  $t("Requests")
                }}</v-btn>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row class="fixedCart-row">
      <v-col cols="12" sm="6" md="3">
        <div class="item item1">
          <v-row>
            <v-col cols="4" class="text-center">
              <div class="image">
                <v-img
                  src="../../assets/icons/student.svg"
                  max-width="50"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="8">
              <div class="item-content">
                <p class="num main-color">{{ counts["total_students"] }}</p>
                <p class="value">{{ $t("Total Student") }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <div class="item item2">
          <v-row>
            <v-col cols="4" class="text-center">
              <div class="image">
                <v-img
                  src="../../assets/icons/parent.svg"
                  max-width="50"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="8">
              <div class="item-content">
                <p class="num">{{ counts["total_parents"] }}</p>
                <p class="value">{{ $t("Total Parent") }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <div class="item item3 unassin-students">
          <v-row>
            <v-col cols="4" class="text-center">
              <div class="image">
                <v-img
                  src="../../assets/icons/teacher.svg"
                  max-width="50"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="8">
              <div class="item-content">
                <p class="num">{{ counts["total_teachers"] }}</p>
                <p class="value">{{ $t("Total Teacher") }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <router-link
          :to="
            '/students?page=1&unAssign=true&active_year=' +
            counts['active_year_id']
          "
        >
          <div class="item item4 unassin-students">
            <v-row>
              <v-col cols="4" class="text-center">
                <div class="image">
                  <v-img
                    src="../../assets/images/pendingPayment.svg"
                    max-width="40"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="8">
                <div class="item-content">
                  <p class="num">{{ counts["student_notAssign_toClass"] }}</p>
                  <p class="value">{{ $t("Unassigned Students") }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <div class="cart-sec pia-chart">
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <h3>{{ $t("Students") }}</h3>
                </v-col>
                <v-col cols="8" sm="8">
                  <v-row>
                    <v-col cols="12" sm="6" style="padding-top: 0">
                      <v-select
                        :items="filterType"
                        item-text="text"
                        item-value="value"
                        v-model="selectedType"
                        dense
                        solo
                        @change="getStudentChartData()"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" style="padding-top: 0">
                      <v-select
                        id="academicPeriods"
                        v-model="selectedAcademicPeriodId"
                        :items="academicPeriods"
                        item-text="en.name"
                        item-value="id"
                        dense
                        solo
                        @change="getStudentChartData()"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" v-if="studentChartData.length == 0">
                  <v-alert type="info">
                    {{ $t("there is no data available") }}
                  </v-alert>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pa-0">
                      <canvas
                        id="student-doughnut-chart"
                        width="250px"
                      ></canvas>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <p class="mt-5 chart-label">
                        <span class="main-color"
                          >{{ $t("Total Students") }} :
                        </span>
                        <span class="">{{ StudentCount }}</span>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <ul class="list-unstyled chartValues">
                    <li
                      v-for="(item, index) in top5StudentChartData"
                      :key="index"
                    >
                      <span class="val capitalized"
                        ><v-icon :style="{ color: item.color }" small
                          >trip_origin</v-icon
                        >
                        {{ item.name }}</span
                      >
                      <span class="num">{{ item.numberOfStudents }}</span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <div class="cart-sec pia-chart">
              <v-row>
                <v-col cols="4" sm="12" md="12">
                  <h3>{{ $t("Applicants Status") }}</h3>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" v-if="studentChartDataStatus.length == 0">
                  <v-alert type="info">
                    {{ $t("there is no data available") }}
                  </v-alert>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pa-0">
                      <canvas
                        id="student-doughnut-chart-status"
                        width="250px"
                      ></canvas>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <p class="mt-5 chart-label">
                        <span class="main-color"
                          >{{ $t("Total Applicants") }} :
                        </span>
                        <span class="">{{ studentChartDataStatusCount }}</span>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <ul class="list-unstyled chartValues">
                    <li
                      v-for="(item, index) in studentChartDataStatus"
                      :key="index"
                    >
                      <span class="val capitalized"
                        ><v-icon :style="{ color: item.color }" small
                          >trip_origin</v-icon
                        >
                        <a
                          v-if="
                            item.status != 'pending_complete_profile' &&
                            item.status != 'student'
                          "
                          :href="
                            '/applicants?page=1&global_status=' + item.status
                          "
                        >
                          {{ item.name }}
                        </a>
                        <a
                          v-if="item.status == 'pending_complete_profile'"
                          href="/students?page=1&global_status=pending"
                        >
                          {{ item.name }}
                        </a>
                        <a
                          v-if="item.status == 'student'"
                          href="/students?page=1&global_status=new"
                        >
                          {{ item.name }}
                        </a></span
                      >
                      <span class="num"> {{ item.numberOfStudents }}</span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <div class="cart-sec earning-chart">
              <v-row>
                <v-col cols="12" sm="4">
                  <h3 class="chart-header">{{ $t("Earnings") }}</h3>
                </v-col>
                <v-col cols="12" sm="4" class="text-right_in_rtl">
                  <label>{{ $t("Month From") }}</label>
                  <v-select
                    :items="months"
                    v-model="filters.monthFrom"
                    item-text="month"
                    item-value="id"
                    class="mr-4"
                    dense
                    solo
                    @change="getEarnings"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" class="text-right_in_rtl">
                  <label>{{ $t("Month To") }}</label>
                  <v-select
                    :items="months"
                    v-model="filters.monthTo"
                    item-text="month"
                    item-value="id"
                    class="mr-4"
                    dense
                    solo
                    @change="getEarnings"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <div class="chart-status">
                  <span v-for="(data, index) in datasets" :key="index">
                    <v-icon :color="data.backgroundColor" small
                      >trip_origin</v-icon
                    >
                    {{ data.label }}
                  </span>
                  <!-- <span class="">
                    <v-icon class="dashboard-yellow" small>trip_origin</v-icon>
                    Fees Collection
                  </span> -->
                </div>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12">
                  <canvas
                    id="earning-line-chart"
                    width="100%"
                    height="320"
                  ></canvas>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <div class="cart-sec" id="calendar">
              <v-row>
                <v-col cols="6" md="6">
                  <h3 class="calendar-title">{{ $t("Event Calendar") }}</h3>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon v-if="currentAppLocale != 'ar'" small
                      >mdi-chevron-left</v-icon
                    >
                    <v-icon v-else small>mdi-chevron-right</v-icon>
                  </v-btn>
                  <div class="main-color canlendar-date">{{ title }}</div>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon v-if="currentAppLocale != 'ar'" small
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else small>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Today
                  </v-btn>
                 
                  <v-spacer></v-spacer> -->

              <v-sheet height="350px" id="calendar">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="events"
                  :event-color="getEventColor"
                  :now="today"
                  :type="type"
                  :event-more="true"
                  @click:event="showEvent"
                  @change="updateRange"
                  :locale="currentAppLocale"
                ></v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card color="grey lighten-4" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-toolbar-title
                        :style="'color:' + selectedEvent.font_color"
                        v-html="selectedEvent.name"
                      ></v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </v-card>
                </v-menu>
              </v-sheet>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <!-- socialmedia Component -->
      <SocialMedia :socialChannels="socialChannels" />
    </v-row>

    <v-dialog v-model="unassigstudentsinfo.dialog" persistent max-width="500px">
      <!-- start dialog for add and edit -->
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Unassigned Students") }}</span>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            class="elevation-1 level1 templates"
            text="left"
            hide-default-footer
          >
            <template v-slot:item.recipients="{ item }">
              <v-tooltip bottom max-width="1000px">
                <template v-slot:activator="{ on }">
                  <p dark v-on="on">
                    {{ item.recipients ? item.recipients.slice(0, 30) : "" }}
                  </p>
                </template>
                <span>{{ item.recipients }}</span>
              </v-tooltip>
              <!-- <p
              v-html="item.recipients ? item.recipients.slice(0, 30) : ''"
              :title="item.recipients"
            > -->
              <!-- {{  }} -->
              <!-- </p> -->
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon
                class="main-color"
                @click="editItem(item)"
                title="Edit"
                :id="item.id"
                >edit</v-icon
              >

              <v-icon class="main-color" :id="item.id" @click="openDia(item)"
                >delete</v-icon
              >
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="modal-btn-cancel cancelBtn" text @click="close">{{
            $t("Cancel")
          }}</v-btn>
          <!--     <v-btn
            class="modal-btn-save saveBtn"
            text
            @click="checkIfDatesChanges"
            :disabled="disabledSubmitbtn"
            >{{ $t("Save") }}</v-btn
          > -->
        </v-card-actions>
      </v-card>

      <!-- end dialog for add/edit -->
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Chart from "chart.js";

import annoucementsDashbordComponent from "../../components/annoucementsDashbordComponent";
import SocialMedia from "../../components/socialmediaComponent/socialmedia";
import { DashboardMixin } from "../../mixins/DashboardMixin";
export default {
  mixins: [DashboardMixin],
  name: "AdminDashboard",
  components: { annoucementsDashbordComponent, SocialMedia },
  data() {
    return {
      dashbaordType: "admin",
      loading: false,
      academicPeriods: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      items: [
        {
          student_name: "Frozen Yogurt",
          student_status: "Unassigned",
          male_females: "Male",
        },
        {
          student_name: "Frozen Yogurt",
          student_status: "Unassigned",
          male_females: "Male",
        },
        {
          student_name: "Frozen Yogurt",
          student_status: "Unassigned",
          male_females: "Male",
        },
        {
          student_name: "Frozen Yogurt",
          student_status: "Unassigned",
          male_females: "Male",
        },
        {
          student_name: "Frozen Yogurt",
          student_status: "Unassigned",
          male_females: "Male",
        },
        {
          student_name: "Frozen Yogurt",
          student_status: "Unassigned",
          male_females: "Male",
        },
      ],
      headers: [
        {
          text: this.$i18n.t("Students"),
          sortable: false,
          value: "student_name",
        },
        { text: this.$i18n.t("Status"), value: "student_status" },
        {
          text: this.$i18n.t("Gender"),
          value: "male_females",
        },
      ],
      unassigstudentsinfo: { dialog: false },
      selectedAcademicPeriodId: "",
      activeAcademicPeriod: "",
      is_imployee: false,
      filterType: [
        { value: "gender", text: this.$i18n.t("Gender") },
        { value: "grade", text: this.$i18n.t("Grade") },
        { value: "nationality", text: this.$i18n.t("Nationality") },
      ],
      selectedType: "nationality",
      studentChartData: [],
      top5StudentChartData: [],
      focus: "",
      type: "month",
      typeToLabel: {
        month: this.$i18n.t("Month"),
        week: this.$i18n.t("Week"),
        day: this.$i18n.t("Day"),
        "4day": this.$i18n.t("4 Days"),
      },
      mode: "column",
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      studentChart: "",
      studentChartStatus: "",
      events: [],
      colors: [],
      names: [],
      values: [],
      StudentCount: 0,
      counts: [],
      years: [],
      months: [],
      filters: { monthFrom: "", monthTo: "" },
      datasets: [],
      labels: [],
      earningChart: "",
      studentChartDataStatus: "",
      studentChartDataStatusCount: 0,
      colorsStatus: "",
      namesStatus: "",
      valuesStatus: "",
    };
  },

  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },

  methods: {
    getRandomColor(index) {
      let colorArray = [
        "#7297FF",
        "#56d9fe",
        "#FFB400",
        "#FFFF99",
        "#00B3E6",
        "#E6B333",
        "#3366E6",
        "#999966",
        "#99FF99",
        "#B34D4D",
        "#80B300",
        "#809900",
        "#E6B3B3",
        "#6680B3",
        "#66991A",
        "#FF99E6",
        "#CCFF1A",
        "#FF1A66",
        "#E6331A",
        "#33FFCC",
        "#66994D",
        "#B366CC",
        "#4D8000",
        "#B33300",
        "#CC80CC",
        "#66664D",
        "#991AFF",
        "#E666FF",
        "#4DB3FF",
        "#1AB399",
        "#E666B3",
        "#33991A",
        "#CC9999",
        "#B3B31A",
        "#00E680",
        "#4D8066",
        "#809980",
        "#E6FF80",
        "#1AFF33",
        "#999933",
        "#FF3380",
        "#CCCC00",
        "#66E64D",
        "#4D80CC",
        "#9900B3",
        "#E64D66",
        "#4DB380",
        "#FF4D4D",
        "#99E6E6",
        "#6666FF",
      ];

      return colorArray[index];
    },
    createPiaChart() {
      let colors = this.colors;
      let names = this.names;
      let values = this.values;
      let studentCount = this.StudentCount;

      if (studentCount == 0) {
        studentCount = "";
      }

      if (this.studentChart) {
        this.studentChart.destroy();
      }

      if ($("#student-doughnut-chart").length) {
        var doughnutChartData = {
          labels: names,
          datasets: [
            {
              backgroundColor: colors,
              data: values,
              label: this.$i18n.t("Total Students"),
            },
          ],
        };
        var doughnutChartOptions = {
          responsive: true,
          maintainAspectRatio: false,

          animation: {
            duration: 1000,
          },
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
          },
        };
        var studentCanvas = $("#student-doughnut-chart")
          .get(0)
          .getContext("2d");
        // eslint-disable-next-line no-unused-vars
        this.studentChart = new Chart(studentCanvas, {
          type: "doughnut",
          data: doughnutChartData,
          options: doughnutChartOptions,
          // plugins: [
          //   {
          //     beforeDraw: function(chart) {
          //       var width = chart.chart.width,
          //         height = chart.chart.height,
          //         ctx = chart.chart.ctx;

          //       ctx.restore();
          //       var fontSize = (height / 114).toFixed(2);
          //       ctx.font = fontSize + "em sans-serif";
          //       ctx.textBaseline = "middle";

          //       var text = studentCount,
          //         textX =
          //           this.currentAppLocale == "en" ||
          //           this.currentAppLocale == undefined
          //             ? Math.round((width - ctx.measureText(text).width) / 2)
          //             : Math.round((width - ctx.measureText(text).width) / 1.3),
          //         textY = height / 2;

          //       ctx.fillText(text, textX, textY);
          //       ctx.save();
          //     },
          //   },
          // ],
        });
      }
    },
    createPiaChartStudentStatus() {
      let colors = this.colorsStatus;
      let names = this.namesStatus;
      let values = this.valuesStatus;
      let studentCount = this.studentChartDataStatusCount;

      if (studentCount == 0) {
        studentCount = "";
      }
      if (this.studentChartStatus) {
        this.studentChartStatus.destroy();
      }

      if ($("#student-doughnut-chart-status").length) {
        var doughnutChartData = {
          labels: names,
          datasets: [
            {
              backgroundColor: colors,
              data: values,
              label: "Total Students",
            },
          ],
        };
        var doughnutChartOptions = {
          responsive: true,
          maintainAspectRatio: false,

          animation: {
            duration: 1000,
          },
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
          },
        };
        var studentCanvas = $("#student-doughnut-chart-status")
          .get(0)
          .getContext("2d");
        // eslint-disable-next-line no-unused-vars
        this.studentChartStatus = new Chart(studentCanvas, {
          type: "doughnut",
          data: doughnutChartData,
          options: doughnutChartOptions,
          // plugins: [
          //   {
          //     beforeDraw: function(chart) {
          //       var width = chart.chart.width,
          //         height = chart.chart.height,
          //         ctx = chart.chart.ctx;

          //       ctx.restore();
          //       var fontSize = (height / 114).toFixed(2);
          //       ctx.font = fontSize + "em sans-serif";
          //       ctx.textBaseline = "middle";

          //       var text = studentCount,
          //         textX =
          //           this.currentAppLocale == "en" ||
          //           this.currentAppLocale == undefined
          //             ? Math.round((width - ctx.measureText(text).width) / 1.8)
          //             : Math.round((width - ctx.measureText(text).width) / 1.1),
          //         textY = height / 2;

          //       ctx.fillText(text, textX, textY);
          //       ctx.save();
          //     },
          //   },
          // ],
        });
      }
    },

    getStudentChartData() {
      axios
        .post(
          this.getApiUrl + "/students/studentNumbersBasedOnSelection",
          {
            type: this.selectedType,
            year_id: this.selectedAcademicPeriodId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.studentChartData = response.data.data;
            this.colors = [];
            this.names = [];
            this.values = [];
            this.StudentCount = 0;

            if (this.studentChartData.length > 5) {
              this.top5StudentChartData = this.studentChartData.slice(0, 5);
            } else if (this.studentChartData.length <= 5) {
              this.top5StudentChartData = this.studentChartData;
            }

            this.studentChartData.forEach((item, index) => {
              item.color = this.getRandomColor(index);
              this.StudentCount += item.numberOfStudents;
              this.colors.push(item.color);
              this.names.push(item.name);
              this.values.push(item.numberOfStudents);
            });
            this.StudentCount =
              this.StudentCount > 1000
                ? this.StudentCount / 1000 + this.$i18n.t("k")
                : this.StudentCount;
            this.createPiaChart();
          }
        });
    },
    getStudentChartDataStatus() {
      axios
        .get(
          this.getApiUrl + "/dashboard/admin/StudentStatus",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.studentChartDataStatus = response.data.data;
            this.colorsStatus = [];
            this.namesStatus = [];
            this.valuesStatus = [];

            this.studentChartDataStatus.forEach((item, index) => {
              item.color = this.getRandomColor(index);
              this.studentChartDataStatusCount += parseInt(
                item.numberOfStudents
              );
              this.colorsStatus.push(item.color);
              this.namesStatus.push(item.name);
              this.valuesStatus.push(item.numberOfStudents);
            });
            this.studentChartDataStatusCount =
              this.studentChartDataStatusCount > 1000
                ? this.studentChartDataStatusCount / 1000 + this.$i18n.t("k")
                : this.studentChartDataStatusCount;
            this.createPiaChartStudentStatus();
          }
        });
    },

    getAcademicPeriods() {
      axios
        .get(this.getApiUrl + "/school/showAcademicPeriod?list=yes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.academicPeriods = response.data.data;
            if (this.academicPeriods.length > 0) {
              this.selectedAcademicPeriodId = this.academicPeriods.find(
                (object) => object.is_active == 1
              ).id;
            }

            this.activeAcademicPeriod = this.selectedAcademicPeriodId;
            this.getStudentChartData();
          }
        });
    },

    createLineChart() {
      /*-------------------------------------
          Line Chart 
      -------------------------------------*/
      if (this.earningChart) {
        this.earningChart.destroy();
      }
      if ($("#earning-line-chart").length) {
        var lineChartData = {
          labels: this.labels.length > 0 ? this.labels : [],
          datasets: this.datasets.length > 0 ? this.datasets : [],
        };
        var lineChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 2000,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            display: false,
          },

          tooltips: {
            mode: "index",
            intersect: false,
            enabled: true,
          },
        };
        var earningCanvas = $("#earning-line-chart").get(0).getContext("2d");
        // eslint-disable-next-line no-unused-vars
        this.earningChart = new Chart(earningCanvas, {
          type: "line",
          data: lineChartData,
          options: lineChartOptions,
        });
      }
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      // const events = [];
      axios
        .post(this.getApiUrl + "/school/getVacationsInPeriod", [], {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.events = response.data.data;
          }
        });

      this.start = start;
      this.end = end;
      // this.events = events;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    getCounts() {
      axios
        .get(this.getApiUrl + "/dashboard/admin", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.counts = response.data.data;
          }
        });
    },
    getMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.months = response.data.data;
        });
    },
    openDialog() {
      this.unassigstudentsinfo.dialog = true;
    },
    close() {
      this.unassigstudentsinfo.dialog = false;
    },
    getEarnings() {
      this.labels = [];
      this.datasets = [];
      axios
        .post(this.getApiUrl + "/dashboard/admin/GetEarnings", this.filters, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            let datasets = response.data.data;
            this.datasets = [...datasets.datasets];
            this.labels = [...datasets.labels];
            // console.log(newArray);

            if (this.datasets.length > 0) {
              // console.log("earning");
              this.createLineChart();
            } else {
              if (this.earningChart) {
                this.earningChart.destroy();
              }
            }
          }
        });
    },
  },
  mounted() {
    this.getMonths();
    this.createPiaChart();
    this.getStudentChartDataStatus();
    // this.createBarChart();
    this.getEarnings();
    this.getAcademicPeriods();
    this.$refs.calendar.checkChange();
    this.getCounts();
    if (localStorage.is_employee == "true") {
      this.is_imployee = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_dashboard.scss";

.item .item-content .value {
  font-size: 0.75rem;
}
.item2 .item-content .num {
  color: $dashboard-purple;
}
.item3 .item-content .num {
  color: $dashboard-blue;
}
.item4 .item-content .num {
  color: $secondary-color;
}
.unassin-students {
  cursor: pointer;
}
.earning-chart,
#calendar {
  height: 500px;
}

.chart-label {
  font-size: 1.2rem;
  text-align: center;
}
canvas {
  height: 200px !important;
}
@media (min-width: 960px) {
  .box {
    display: none;
  }
  .fixedCart-row {
    display: flex;
    .item {
      height: 90px;
    }
    .col-md-3 {
      padding-right: 0;
    }
    .col-md-3:first-child {
      padding-left: 12px;
    }
  }
  .notificationScroll {
    display: none;
  }
}
.hidden {
  display: none !important;
}

@media (max-width: 600px) {
  #student-doughnut-chart {
    height: 200px !important;
    width: 200px !important;
    margin: 0 auto;
  }
  #student-doughnut-chart-status {
    height: 180px !important;
    width: 180px !important;
    margin: 0 auto;
  }
  .chartValues {
    margin-top: 0 !important;
  }
}
.chartValues {
  margin-top: 0 !important;
}
</style>
